import { Link, StaticQuery, graphql } from "gatsby"
import React from "react"
import Img from "gatsby-image"

import FeaturedStyles from "./featured.module.css"
import blog from "../images/blog.png"
import inspiration from "../images/inspiration.png"
import patterns from "../images/patterns.png"


const Featured = (props) => {
  return (
    <div className={FeaturedStyles.featured}>
      <StaticQuery
        query={graphql`
          {
            allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, limit: 2) {
              edges {
                node {
                  fields {
                    slug
                  }
                  excerpt(pruneLength: 250)
                  frontmatter {
                    date(formatString: "MMMM Do YYYY")
                    title
                    categories
                    featuredImage {
                      childImageSharp {
                        fluid(maxWidth: 800) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => data.allMarkdownRemark.edges.map(edge => {
          let featuredImgFluid = edge.node.frontmatter.featuredImage.childImageSharp.fluid
          return(
            <article className={FeaturedStyles.post}>
            <Img className={FeaturedStyles.image} fluid={featuredImgFluid} alt={edge.node.frontmatter.title} />
            <div className={FeaturedStyles.info}>
            <h3>{edge.node.frontmatter.title}</h3>
            <span className={FeaturedStyles.meta}>{edge.node.frontmatter.date} | {edge.node.frontmatter.categories}</span>
            </div>
            <Link to={edge.node.fields.slug}></Link>
            </article>
          )
        })}
      ></StaticQuery>
      <div className={FeaturedStyles.categories}>
        <div className={FeaturedStyles.category}>
          <img src={blog} alt="blog" />
          <span>Blog</span>
          <Link to="/blog"></Link>
        </div>
        <div className={FeaturedStyles.category}>
          <img src={patterns} alt="patterns" />
          <span>Patterns</span>
          <Link to="/categories/patterns"></Link>
        </div>
        <div className={FeaturedStyles.category}>
          <img src={inspiration} alt="Food" />
          <span>Inspiration</span>
          <Link to="/inspiration"></Link>
        </div>
      </div>
    </div>
  )
}

export default Featured