import React from "react"

import Layout from "../components/layout"
import Author from "../components/author"
import Posts from "../components/posts"
import Featured from "../components/featured"
import SEO from "../components/seo";

function IndexPage() {
  return (
    <Layout>
      <SEO title={"Homepage"}></SEO>
      <Featured></Featured>
      <Author></Author>
      <Posts></Posts>
    </Layout>
  )
}

export default IndexPage

//https://guides.github.com/features/mastering-markdown/