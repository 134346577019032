import React from "react"

import AuthorStyles from "./author.module.css"

import author from "../images/author.jpg"

const Author = () => {
  return(
    <div className={AuthorStyles.authorBg}>
      <div className={AuthorStyles.author}>
        <h2>About rekas</h2>
        <img src={author} alt="author" />
        <p>Beginner crafter, currently experimenting with crochet. Passionate about creating warmth, and hoping to
          spread the love for all things handmade.</p>
      </div>

    </div>
  )
}


export default Author

//https://www.gatsbyjs.com/docs/css-in-js/